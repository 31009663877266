import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from './base-data.service';
import { UserRoutineDto } from '../../models/user-routine.model';
import { FirestoreService } from '../firestore/firestore.service';
import { UtilService } from '../util/util.service';

@Injectable()
export class UserRoutineDataService extends BaseDataService<UserRoutineDto> {
    constructor(private firestore: FirestoreService, private util: UtilService) {
        super('user-routine');
    }

    public get(): Observable<UserRoutineDto[]> {
        return this.firestore.get<UserRoutineDto>(this.baseCollection);
    }

    public getUserData(): Observable<UserRoutineDto[]> {
        return this.firestore.get<UserRoutineDto>(this.baseCollection);
    }

    public getOne(id: string): Observable<UserRoutineDto> {
        return this.firestore.getOne<UserRoutineDto>(this.baseCollection, id);
    }

    public update(data: Partial<UserRoutineDto>): Promise<void> {
        return this.firestore.update<UserRoutineDto>(this.baseCollection, data.id, data);
    }

    public delete(id: string): Promise<any> {
        return this.firestore.delete(this.baseCollection, id);
    }
    
    public create(data: UserRoutineDto): Promise<void> {
        return this.firestore.create(this.baseCollection, data);
    }
}