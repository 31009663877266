import { UUID } from 'angular2-uuid';
import { BaseDatabaseModel, BaseDto } from './base-dto.model';

export class FeedbackModel extends BaseDatabaseModel {

    constructor(
        id: string, 
        uid: string, 
        type: string,
        subject: string,
        message: string,
        notes: string,
        createdAt?: string,
        updatedAt?: string,
        resolvedAt?: string,
        deletedAt?: string
        ) {
        super();
        this.id = id;
        this.uid = uid;
        this.type = type;
        this.subject = subject;
        this.message = message;
        this.notes = notes;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.resolvedAt = resolvedAt;
        this.deletedAt = deletedAt;
    }
    public id: string;
    public uid: string;
    public type: string;
    public subject: string;
    public message: string;
    public notes: string;
    public createdAt?: string;
    public updatedAt?: string;
    public resolvedAt?: string;
    public deletedAt?: string;

    public static fromDto(feedback: FeedbackDto): FeedbackModel {
        return new FeedbackModel(
            feedback.id, 
            feedback.uid, 
            feedback.type, 
            feedback.subject, 
            feedback.message, 
            feedback.notes, 
            feedback.createdAt, 
            feedback.updatedAt, 
            feedback.resolvedAt, 
            feedback.deletedAt, 
            );
    }

    public static emptyDto(): FeedbackDto {
        const datetime = new Date().toISOString();
        return {
            id: UUID.UUID(),
            type: null,
            uid: null,
            subject: null,
            message: null,
            notes: null,
            createdAt: datetime,
            updatedAt: datetime,
            resolvedAt: null,
            deletedAt: null,
        }
    }

    public toDto(): FeedbackDto {
        return {
            id: this.id,
            uid: this.uid,
            type: this.type,
            subject: this.subject,
            message: this.message,
            notes: this.notes,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            resolvedAt: this.resolvedAt,
            deletedAt: this.deletedAt,
        };
    }
}

export interface FeedbackDto extends BaseDto {
    id: string, 
    uid: string, 
    type: string,
    subject: string,
    message: string,
    notes: string,
    createdAt?: string,
    updatedAt?: string,
    resolvedAt?: string,
    deletedAt?: string
}