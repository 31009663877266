import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { PozerDataService } from '../../core/services/data-services/pozer-data.service';
import { PozerDto } from 'src/app/core/models/pozer.model';

@Component({
  selector: 'app-pozer-pozers',
  templateUrl: './pozer-pozers.component.html',
  styleUrls: ['./pozer-pozers.component.scss'],
})
export class PozerPozersComponent implements OnInit {
  public pozers: PozerDto[];

  constructor(
    private popoverController: PopoverController,
    private pozerDataService: PozerDataService
  ) { 
    this.pozerDataService.get().subscribe(data => {
      this.pozers = data;
    });
  }

  ngOnInit() {}

  save(pozer: any) {
    const data = { value: pozer.id };
    this.popoverController.dismiss(data);
  }

}
