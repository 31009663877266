import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from './base-data.service';
import { UserFavortieDto } from '../../models/user-favorite.model';
import { FirestoreService } from '../firestore/firestore.service';
import { UtilService } from '../util/util.service';

@Injectable()
export class UserFavoriteDataService extends BaseDataService<UserFavortieDto> {
    constructor(private firestore: FirestoreService, private util: UtilService) {
        super('user-favorite');
    }

    public get(): Observable<UserFavortieDto[]> {
        return this.firestore.get<UserFavortieDto>(this.baseCollection);
    }

    public getUserData(): Observable<UserFavortieDto[]> {
        return this.firestore.get<UserFavortieDto>(this.baseCollection);
    }

    public getOne(id: string): Observable<UserFavortieDto> {
        return this.firestore.getOne<UserFavortieDto>(this.baseCollection, id);
    }

    public update(data: Partial<UserFavortieDto>): Promise<void> {
        return this.firestore.update<UserFavortieDto>(this.baseCollection, data.id, data);
    }

    public delete(id: string): Promise<any> {
        return this.firestore.delete(this.baseCollection, id);
    }
    
    public create(data: UserFavortieDto): Promise<void> {
        return this.firestore.create(this.baseCollection, data);
    }
}