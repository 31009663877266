// export class Week {
//     sunday?     : any;
//     monday?     : any;
//     tuesday?    : any;
//     wednesday?  : any;
//     thursday?   : any;
//     friday?     : any;
//     saturday?   : any;
//     constructor(values: Object = {}) {
//         Object.assign(this, values);
//    }
//  } 

export class WeekModel {

    constructor(
      sunday     : boolean,
      monday     : boolean,
      tuesday    : boolean,
      wednesday  : boolean,
      thursday   : boolean,
      friday     : boolean,
      saturday   : boolean
    ) {
        this.sunday = sunday;
        this.monday = monday;
        this.tuesday = tuesday;
        this.wednesday = wednesday;
        this.thursday = thursday;
        this.friday = friday;
        this.saturday = saturday;
    }
    public sunday: boolean;
    public monday: boolean;
    public tuesday: boolean;
    public wednesday: boolean;
    public thursday: boolean;
    public friday: boolean;
    public saturday: boolean;

    public static fromDto(week: WeekDto): WeekModel {
        return new WeekModel (
          week.sunday, 
          week.monday, 
          week.tuesday,
          week.wednesday,
          week.thursday,
          week.friday,
          week.saturday
        );
      }

    public static emptyDto(): WeekDto {
        return {
            sunday: false,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false
        }
    }

    public toDto(): WeekDto {
        return {
          sunday: this.sunday,
          monday: this.monday,
          tuesday: this.tuesday,
          wednesday: this.wednesday,
          thursday: this.thursday,
          friday: this.friday,
          saturday: this.saturday
        };
    }
}

export interface WeekDto {
  sunday: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
}