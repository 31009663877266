import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from './base-data.service';
import { MembershipDto } from '../../models/membership.model';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable()
export class MembershipDataService extends BaseDataService<MembershipDto> {
    constructor(private firestore: FirestoreService) {
        super('membership');
    }

    public get(): Observable<MembershipDto[]> {
        return this.firestore.get<MembershipDto>(this.baseCollection);
    }

    public getActiveMemberships(): Observable<MembershipDto[]> {
        return this.firestore.getActiveMemberships<MembershipDto>(this.baseCollection);
    }

    public getOne(id: string): Observable<MembershipDto> {
        return this.firestore.getOne<MembershipDto>(this.baseCollection, id);
    }

    public update(data: Partial<MembershipDto>): Promise<void> {
        return this.firestore.update<MembershipDto>(this.baseCollection, data.id, data);
    }

    public delete(id: string): Promise<any> {
        return this.firestore.delete(this.baseCollection, id);
    }
    
    public create(data: MembershipDto): Promise<void> {
        return this.firestore.create(this.baseCollection, data);
    }
}