import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

//Models
import { FeedbackDto, FeedbackModel } from 'src/app/core/models/feedback.model';

//Services
import { AuthenticationService } from '../../../../core/services/firestore/firebase-authentication.service';
import { FeedbackDataService } from '../../../../core/services/data-services/feedback-data.service';

import { tap, map } from 'rxjs/operators';

@Component({
  selector: 'app-pozer-feedback',
  templateUrl: './pozer-feedback.page.html',
  styleUrls: ['./pozer-feedback.page.scss'],
})
export class PozerFeedbackPage implements OnInit {
  public item: FeedbackDto;
  public loginUser: any;
  public id: any;

  constructor(
    private navParams: NavParams,
    private modalController: ModalController,
    private feedbackDataService: FeedbackDataService,
    private authenticationService: AuthenticationService,
  ) {
    this.id = this.navParams.get('id');
  }

  ngOnInit() {
    
    this.authenticationService.checkAuth().then(user => {
      this.loginUser = user;
      this.item = FeedbackModel.emptyDto();
      this.item.uid = this.loginUser.uid;
      if (this.id) {
        this.feedbackDataService.getOne(this.id).pipe(tap(data => {
          this.item = data;
        })).subscribe();
      }
    });
  }

  send() {
    // if (!this.item.type) {
    //   alert(`Please select Type`);
    // } else {
      // if (!this.item.subject) {
      //   alert(`Please add Subject`);
      // } else {
        if (!this.item.message) {
          alert(`Please add Message`);
        } else {
          this.feedbackDataService.create(this.item).then(res => {
            this.modalController.dismiss({
              'dismissed': true
            });
          });
        }
      // }
    // }
  }


  close() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}
