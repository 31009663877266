import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from './base-data.service';
import { FirestoreService } from '../firestore/firestore.service';
import { UtilService } from '../util/util.service';
import { LogDto } from 'src/app/core/models/log.model';

@Injectable()
export class LogDataService extends BaseDataService<LogDto> {
    constructor(private firestore: FirestoreService, private util: UtilService) {
        super('log');
    }

    public get(): Observable<LogDto[]> {
        return this.firestore.getUserData<LogDto>(this.baseCollection);
    }

    public getDateLog(date: any): Observable<LogDto[]> {
        return this.firestore.getDateLog<LogDto>(this.baseCollection, date);
    }

    public getOne(id: string): Observable<LogDto> {
        return this.firestore.getOne<LogDto>(this.baseCollection, id);
    }

    public update(data: Partial<LogDto>): Promise<void> {
        return this.firestore.update<LogDto>(this.baseCollection, data.id, data);
    }

    public delete(id: string): Promise<any> {
        return this.firestore.delete(this.baseCollection, id);
    }
    
    public create(data: LogDto): Promise<void> {
        return this.firestore.create(this.baseCollection, data);
    }
}