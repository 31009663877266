import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { PozeDataService } from '../../core/services/data-services/poze-data.service';
import { PozeDto } from 'src/app/core/models/poze.model';

@Component({
  selector: 'app-pozer-poze',
  templateUrl: './pozer-poze.component.html',
  styleUrls: ['./pozer-poze.component.scss'],
})
export class PozerPozeComponent implements OnInit {
  public pozes: PozeDto[];
  public poze: PozeDto;
  constructor(
    private popoverController: PopoverController,
    private pozeDataService: PozeDataService
  ) { 
    this.pozeDataService.get().subscribe(data => {
      this.pozes = data;
    });
  }

  ngOnInit() {}

  save(poze: any) {
    const data = { value: poze.id };
    this.popoverController.dismiss(data);
  }

}
