import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from './base-data.service';
import { RatingDto } from '../../models/rating.model';
import { FirestoreService } from '../firestore/firestore.service';
import { UtilService } from '../util/util.service';
import { PozeDto } from 'src/app/core/models/poze.model';

@Injectable()
export class PozeDataService extends BaseDataService<PozeDto> {
    constructor(private firestore: FirestoreService, private util: UtilService) {
        super('poze');
    }

    public get(): Observable<PozeDto[]> {
        return this.firestore.get<PozeDto>(this.baseCollection);
    }

    public getByPosition(position: string): Observable<PozeDto[]> {
        return this.firestore.getByPosition<PozeDto>(this.baseCollection, position);
    }

    public getByLocation(location: string): Observable<PozeDto[]> {
        return this.firestore.getByLocation<PozeDto>(this.baseCollection, location);
    }

    public getOne(id: string): Observable<PozeDto> {
        return this.firestore.getOne<PozeDto>(this.baseCollection, id);
    }

    public update(data: Partial<PozeDto>): Promise<void> {
        return this.firestore.update<PozeDto>(this.baseCollection, data.id, data);
    }

    public delete(id: string): Promise<any> {
        return this.firestore.delete(this.baseCollection, id);
    }
    
    public create(data: PozeDto): Promise<void> {
        return this.firestore.create(this.baseCollection, data);
    }
}