import { UUID } from 'angular2-uuid';
import { BaseDatabaseModel, BaseDto } from './base-dto.model';
import { TargetAreaDto } from './targetArea.model';

export class PozeModel extends BaseDatabaseModel {

    constructor(
        id: string, 
        position: string, 
        laying: boolean, 
        standing: boolean, 
        sitting: boolean, 
        home: boolean, 
        travel: boolean, 
        work: boolean, 
        title?: string,
        notes?: string,
        summary?: string,
        hamstrings?: number,
        upper_back?: number,
        lower_back?: number,
        shoulders?: number,
        neck?: number,
        core?: number,
        quads?: number,
        hips?: number,
        calves?: number,
        biceps?: number,
        triceps?: number,
        forearms?: number,
        hands?: number,
        butt?: number,
        groin?: number,
        minutes?: number,
        benefits?: Array<String>,
        createdAt?: string,
        updatedAt?: string,
        deletedAt?: string
        ) {
        super();
        this.id = id;
        this.position = position;
        this.laying = laying;
        this.standing = standing;
        this.sitting = sitting;
        this.home = home;
        this.travel = travel;
        this.work = work;
        this.title = title;
        this.notes = notes;
        this.summary = summary;
        this.hamstrings = hamstrings;
        this.upper_back = upper_back;
        this.lower_back = lower_back;
        this.shoulders = shoulders;
        this.neck = neck;
        this.core = core;
        this.quads = quads;
        this.hips = hips;
        this.calves = calves;
        this.biceps = biceps;
        this.triceps = triceps;
        this.forearms = forearms;
        this.hands = hands;
        this.butt = butt;
        this.groin = groin;
        this.minutes = minutes;
        this.benefits = benefits;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.deletedAt = deletedAt;
    }
    public id:string;
    public position: string;
    public laying: boolean;
    public standing: boolean;
    public sitting: boolean;
    public home: boolean;
    public travel: boolean;
    public work: boolean;
    public title?: string;
    public notes?: string;
    public summary?: string;
    public hamstrings?: number;
    public upper_back?: number;
    public lower_back?: number;
    public shoulders?: number;
    public neck?: number;
    public core?: number;
    public quads?: number;
    public hips?: number;
    public calves?: number;
    public biceps?: number;
    public triceps?: number;
    public forearms?: number;
    public hands?: number;
    public butt?: number;
    public groin?: number;
    public minutes?: number;
    public benefits?: Array<String>;
    public createdAt?: string;
    public updatedAt?: string;
    public deletedAt?: string;

    public static fromDto(poze: PozeDto): PozeModel {
        return new PozeModel(
            poze.id, 
            poze.position, 
            poze.laying, 
            poze.standing, 
            poze.sitting, 
            poze.home, 
            poze.travel, 
            poze.work, 
            poze.title,
            poze.notes,
            poze.summary,
            poze.hamstrings,
            poze.upper_back,
            poze.lower_back,
            poze.shoulders,
            poze.neck,
            poze.core,
            poze.quads,
            poze.hips,
            poze.calves,
            poze.biceps,
            poze.triceps,
            poze.forearms,
            poze.hands,
            poze.butt,
            poze.groin,
            poze.minutes,
            poze.benefits,
            poze.createdAt,
            poze.updatedAt,
            poze.deletedAt
            );
    }

    public static emptyDto(): PozeDto {
        const datetime = new Date().toISOString();
        return {
            id: UUID.UUID(),
            position: null,
            laying: false,
            standing: false,
            sitting: false,
            home: false,
            travel: false,
            work: false,
            title: null,
            notes: null,
            summary: null,
            hamstrings: 0,
            upper_back: 0,
            lower_back: 0,
            shoulders: 0,
            neck: 0,
            core: 0,
            quads: 0,
            hips: 0,
            calves: 0,
            biceps: 0,
            triceps: 0,
            forearms: 0,
            hands: 0,
            butt: 0,
            groin: 0,
            minutes: 0,
            benefits: [],
            createdAt: datetime,
            updatedAt: datetime,
            deletedAt: null
        }
    }

    public toDto(): PozeDto {
        return {
            id: this.id,
            position: this.position,
            laying: this.laying,
            standing: this.standing,
            sitting: this.sitting,
            home: this.home,
            travel: this.travel,
            work: this.work,
            title: this.title,
            notes: this.notes,
            summary: this.summary,
            hamstrings: this.hamstrings,
            upper_back: this.upper_back,
            lower_back: this.lower_back,
            shoulders: this.shoulders,
            neck: this.neck,
            core: this.core,
            quads: this.quads,
            hips: this.hips,
            calves: this.calves,
            biceps: this.biceps,
            triceps: this.triceps,
            forearms: this.forearms,
            hands: this.hands,
            butt: this.butt,
            groin: this.groin,
            minutes: this.minutes,
            benefits: this.benefits,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            deletedAt: this.deletedAt
        };
    }
}

export interface PozeDto extends BaseDto {
    id: string;
    position: string;
    laying: boolean;
    standing: boolean;
    sitting: boolean;
    home: boolean;
    travel: boolean;
    work: boolean;
    title?: string;
    notes?: string;
    summary?: string;
    hamstrings?: number;
    upper_back?: number;
    lower_back?: number;
    shoulders?: number;
    neck?: number;
    core?: number;
    quads?: number;
    hips?: number;
    calves?: number;
    biceps?: number;
    triceps?: number;
    forearms?: number;
    hands?: number;
    butt?: number;
    groin?: number;
    minutes?: number;
    benefits?: Array<String>;
    createdAt?: string;
    updatedAt: string;
    deletedAt: string;
}