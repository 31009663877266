import { Component } from '@angular/core';

// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Platform, ModalController, MenuController } from '@ionic/angular';
import { AuthenticationService } from './core/services/firestore/firebase-authentication.service';
import { UtilService } from './core/services/util/util.service';
import { UserDataService } from './core/services/data-services/user-data.service';
import { timer, Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserDto, UserModel } from './core/models/user.model';
import { PozeDataService } from './core/services/data-services/poze-data.service';
import { RoutineDataService } from './core/services/data-services/routine-data.service';
import { PozerDataService } from './core/services/data-services/pozer-data.service';
import { FavoriteDataService } from './core/services/data-services/favorite-data.service';
import { tap, share } from 'rxjs/operators';
import { Events } from './core/services/events.service';

import { SplashScreen } from '@capacitor/splash-screen';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  sidemenu = 1;
  showChildren = '';
  appPages: any;
  adminPages: any;
  showSplash = true; // <-- show animation
  user: any;
  thisUser: UserDto;

  public adminDrawer: boolean = false; // <-- show superAdmin

  // Admin Values
  cnt_users: any = 0;
  cnt_routine: any = 0;
  cnt_poze: any = 0;
  cnt_pozer: any = 0;
  cnt_usr_likes: any = 0;
  cnt_usr_dislikes: any = 0;
  cnt_usr_favorites: any = 0;

  constructor(
    private authService: AuthenticationService,
    private afAuth: AngularFireAuth,
    private utilService: UtilService,
    private platform: Platform,
    // private splashScreen: SplashScreen,
    // private statusBar: StatusBar,
    public modalCtrl: ModalController,
    private menuCtrl: MenuController,
    private events: Events,
    private userDataService: UserDataService,
    private pozeDataService: PozeDataService,
    private routineDataService: RoutineDataService,
    private pozerDataService: PozerDataService,
    private favoriteDataService: FavoriteDataService,
  ) {
    this.initializeApp();

    this.thisUser = UserModel.emptyDto();

    

    this.appPages = [
      {
        title: 'Home',
        url: '/home',
        direct: 'root',
        icon: 'home',
        active: true

      },
      {
        title: 'Profile',
        url: '/pozer-profile',
        direct: 'forward',
        icon: 'person'
      },
      {
        title: 'Settings',
        url: '/pozer-settings',
        direct: 'forward',
        icon: 'settings'
      },
      {
        title: 'Membership',
        url: '/pozer-membership',
        direct: 'forward',
        icon: 'bulb'
      },
      {
        title: 'Tour',
        url: '/pozer-tour',
        direct: 'forward',
        icon: 'walk'
      },
      {
        title: 'Help',
        url: '/pozer-help',
        direct: 'forward',
        icon: 'help-circle-outline'
      }
    ];

    this.adminPages = [
      {
        title: 'Home',
        url: '/home',
        direct: 'root',
        icon: 'walk',
      },
      {
        title: 'Daily Log',
        url: '/pozer-daily',
        direct: 'root',
        icon: 'walk',
      },
      {
        title: 'Membership Plans',
        url: '/pozer-admin-membership-list',
        direct: 'forward',
        icon: 'walk'
      },
      {
        title: 'User List',
        url: '/pozer-admin-user-list',
        direct: 'forward',
        icon: 'walk'
      },
      {
        title: 'Pozer List',
        url: '/pozer-admin-pozer-list',
        direct: 'forward',
        icon: 'walk'
      },
      {
        title: 'Poze List',
        url: '/pozer-admin-poze-list',
        direct: 'forward',
        icon: 'walk'
      },
      {
        title: 'Routine List',
        url: '/pozer-admin-routine-list',
        direct: 'forward',
        icon: 'walk'
      },
      {
        title: 'Setup Slides',
        url: '/pozer-setup-walkthrough',
        direct: 'forward',
        icon: 'walk'
      },
      {
        title: 'Verify Email',
        url: '/pozer-verify',
        direct: 'forward',
        icon: 'walk'
      },
      {
        title: 'Notifications',
        url: '/pozer-admin-notifications-list',
        direct: 'forward',
        icon: 'walk'
      },
      {
        title: 'Muscle Groups',
        url: '/pozer-admin-muscle-grp',
        direct: 'forward',
        icon: 'walk'
      },
      {
        title: 'In App Purchases',
        url: '/pozer-admin-iap2',
        direct: 'forward',
        icon: 'walk'
      },
      {
        title: 'Welcome Screen',
        url: '/pozer-welcome',
        direct: 'forward',
        icon: 'walk'
      },
      {
        title: 'Tour',
        url: '/pozer-tour',
        direct: 'forward',
        icon: 'walk'
      }


    ];

  }

  logout() {
    this.authService.logout().then(data => {
      this.utilService.navigate('login', false);
    })
  }

  initializeApp() {
    // await SplashScreen.show({
    //   showDuration: 2000,
    //   autoHide: true
    // });
    // this.showSplash = false

    this.platform.ready().then(() => {
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.
      // this.statusBar.styleDefault();
      // this.splashScreen.hide();

      timer(5000).subscribe(() => {
        this.showSplash = false
      });
      this.listenToLoginEvents();
      this.refreshUser()
    });
  }

  showSidemenu(index) {
    this.sidemenu = index + 1;
  }

  listenToLoginEvents() {
    this.events.subscribe('user:login', (data: any) => {
      console.log('Welcome', data.user, 'at', data.time);
      this.refreshUser();
    });

    this.events.subscribe('user:signup', (data: any) => {
      console.log('Welcome', data.user, 'at', data.time);
      this.refreshUser();
    });

    this.events.subscribe('user:logout', (data: any) => {
      console.log('Welcome', data.user, 'at', data.time);
      this.refreshUser();
    });

    this.events.subscribe('user:verified', (data: any) => {
      console.log('Welcome', data.user, 'at', data.time);
      this.refreshUser();
    });
  }

  refreshUser() {
    this.afAuth.authState.subscribe(userAuth => {
      if (userAuth) {
        this.user = userAuth;
        this.setCounts();
        this.userDataService.getOne(this.user.uid).subscribe(data => {
          this.thisUser = data;
        });
        this.utilService.navigate('/home', false);
      } else {
        this.user = null;
        this.utilService.navigate('/login', false);
      }
    });
  }

  navSettings() {
    this.utilService.navigate('/pozer-settings', true);
  }

  navMembership() {
    this.utilService.navigate('/pozer-membership', true);
  }

  setCounts() {
    // User Counts
    this.favoriteDataService.getFavoritesByUserId(this.user.uid).subscribe(data => {
      this.cnt_usr_favorites = data.length;
    });

    // Admin Counts
    this.userDataService.get().subscribe(data => {
      const items: any = data;
      this.cnt_users = items.length;
    });

    this.routineDataService.get().subscribe(data => {
      const items: any = data;
      this.cnt_routine = items.length;
    });

    this.pozeDataService.get().subscribe(data => {
      const items: any = data;
      this.cnt_poze = items.length;
    });

    this.pozerDataService.get().subscribe(data => {
      const items: any = data;
      this.cnt_pozer = items.length;
    });
  }

  showAdmin() {
    this.adminDrawer = true;
    if (this.adminDrawer) {
      this.menuCtrl.enable(true, 'menuAdmin');
      this.menuCtrl.open('menuAdmin');
    }
  }

}
