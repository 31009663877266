import { FocusDto, FocusModel } from './focus.model';

export class UserModel {

    constructor(
        id: string,
        email: string,
        memberCode: string,
        salesCode: string,
        userName?: string,
        avatar?: string,
        fcmToken?: string,
        superAdmin?: boolean,
        ambassador?: boolean,
        pozerTour?: boolean,
        pozerProfile?: boolean,
        name?: string,
        membershipTitle?: string,
        membershipDescription?: string,
        membershipId?: string,
        membershipProductId?: string,
        membershipDate?: string,
        membershipExpires?: string,
        enableHealth?: boolean,
        language?: string,
        country?: string,
        dateBorn?: string,
        height?: string,
        weight?: string,
        focus?: FocusDto,
        commute?: string,
        wakeTime?: string,
        lunchTime?: string,
        dinnerTime?: string,
        bedTime?: string,
        favMovie?: string,
        pozerId?: string,
        menBody?: boolean,
        createdAt?: string,
        updatedAt?: string,
        deletedAt?: string
    ) {
        this.id = id;
        this.email = email;
        this.memberCode = memberCode;
        this.salesCode = salesCode;
        this.userName = userName;
        this.avatar = avatar;
        this.fcmToken = fcmToken;
        this.superAdmin = superAdmin;
        this.ambassador = ambassador;
        this.pozerTour = pozerTour;
        this.pozerProfile = pozerProfile;
        this.name = name;
        this.membershipTitle = membershipTitle;
        this.membershipDescription = membershipDescription;
        this.membershipId = membershipId;
        this.membershipProductId = membershipProductId;
        this.membershipDate = membershipDate;
        this.membershipExpires = membershipExpires;
        this.enableHealth = enableHealth;
        this.language = language;
        this.country = country;
        this.dateBorn = dateBorn;
        this.height = height;
        this.weight = weight;
        this.focus = focus;
        this.commute = commute;
        this.wakeTime = wakeTime;
        this.lunchTime = lunchTime;
        this.dinnerTime = dinnerTime;
        this.bedTime = bedTime;
        this.favMovie = favMovie;
        this.pozerId = pozerId;
        this.menBody = menBody;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.deletedAt = deletedAt;
    }
    public id: string;
    public email: string;
    public memberCode: string;
    public salesCode: string;
    public userName?: string;
    public avatar?: string;
    public fcmToken?: string;
    public superAdmin?: boolean;
    public ambassador?: boolean;
    public pozerTour?: boolean;
    public pozerProfile?: boolean;
    public name:string;
    public membershipTitle?: string;
    public membershipDescription?: string;
    public membershipId?: string;
    public membershipProductId?: string;
    public membershipDate?: string;
    public membershipExpires?: string;
    public enableHealth?: boolean;
    public language?: string;
    public country?: string;
    public dateBorn?: string;
    public height?: string;
    public weight?: string;
    public focus?: FocusDto;
    public commute?: string;
    public wakeTime?: string;
    public lunchTime?: string;
    public dinnerTime?: string;
    public bedTime?: string;
    public favMovie?: string;
    public pozerId?: string;
    public menBody?: boolean;
    public createdAt?: string;
    public updatedAt?: string;
    public deletedAt?: string;

    public static fromDto(user: UserDto): UserModel {
        return new UserModel(
            user.id,
            user.email,
            user.memberCode,
            user.salesCode,
            user.userName,
            user.avatar,
            user.fcmToken,
            user.superAdmin,
            user.ambassador,
            user.pozerTour,
            user.pozerProfile,
            user.name, 
            user.membershipTitle,
            user.membershipDescription,
            user.membershipId,
            user.membershipProductId,
            user.membershipDate,
            user.membershipExpires,
            user.enableHealth,
            user.language,
            user.country,
            user.dateBorn,
            user.height,
            user.weight,
            user.focus,
            user.commute,
            user.wakeTime,
            user.lunchTime,
            user.dinnerTime,
            user.bedTime,
            user.favMovie,
            user.pozerId,
            user.menBody,
            user.createdAt,
            user.updatedAt,
            user.deletedAt
        );
    }

    public static emptyDto(): UserDto {
        const datetime = new Date().toISOString();
        return {
            id: null,
            email: null,
            memberCode: null,
            salesCode: null,
            userName: null,
            avatar: null,
            fcmToken: null,
            superAdmin: false,
            ambassador: false,
            pozerTour: false,
            pozerProfile: false,
            name: null,
            membershipTitle: null,
            membershipDescription: null,
            membershipId: null,
            membershipProductId: null,
            membershipDate: null,
            membershipExpires: null,
            enableHealth: false,
            language: 'en',
            country: 'us',
            dateBorn: null,
            height: '',
            weight: '',
            focus: FocusModel.emptyDto(),
            commute: '',
            wakeTime: '07:00',
            lunchTime: '12:00',
            dinnerTime: '18:00',
            bedTime: '22:00',
            favMovie: '',
            pozerId: 'be52beef-7e9e-f74c-68e5-487c44d151ca',
            menBody: true,
            createdAt: datetime,
            updatedAt: datetime,
            deletedAt: null
        }
    }

    public toDto(): UserDto {
        return {
            id: this.id,
            email: this.email,
            memberCode: this.memberCode,
            salesCode: this.salesCode,
            userName: this.userName,
            avatar: this.avatar,
            fcmToken: this.fcmToken,
            superAdmin: this.superAdmin,
            ambassador: this.ambassador,
            pozerTour: this.pozerTour,
            pozerProfile: this.pozerProfile,
            name: this.name,
            membershipTitle: this.membershipTitle,
            membershipDescription: this.membershipDescription,
            membershipId: this.membershipId,
            membershipProductId: this.membershipProductId,
            membershipDate: this.membershipDate,
            membershipExpires: this.membershipExpires,
            enableHealth: this.enableHealth,
            language: this.language,
            country: this.country,
            dateBorn: this.dateBorn,
            height: this.height,
            weight: this.weight,
            focus: this.focus,
            commute: this.commute,
            wakeTime: this.wakeTime,
            lunchTime: this.lunchTime,
            dinnerTime: this.dinnerTime,
            bedTime: this.bedTime,
            favMovie: this.favMovie,
            pozerId: this.pozerId,
            menBody: this.menBody,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            deletedAt: this.deletedAt
        };
    }
}

export interface UserDto {
    id: string;
    email: string;
    memberCode: string;
    salesCode: string;
    userName?: string;
    avatar?: string;
    fcmToken?: string;
    superAdmin?: boolean;
    ambassador?: boolean;
    pozerTour?: boolean;
    pozerProfile?: boolean;
    name: string;
    membershipTitle?: string;
    membershipDescription?: string;
    membershipId?: string;
    membershipProductId?: string;
    membershipDate?: string;
    membershipExpires?: string;
    enableHealth?: boolean;
    language?: string;
    country?: string;
    dateBorn?: string;
    height?: string;
    weight?: string;
    focus?: FocusDto;
    commute?: string;
    wakeTime?: string;
    lunchTime?: string;
    dinnerTime?: string;
    bedTime?: string;
    favMovie?: string;
    pozerId?: string;
    menBody?: boolean;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
}