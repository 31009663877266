import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from './base-data.service';
import { FirestoreService } from '../firestore/firestore.service';
import { UtilService } from '../util/util.service';
import { FavoriteDto } from 'src/app/core/models/favorite.model';

@Injectable()
export class FavoriteDataService extends BaseDataService<FavoriteDto> {
    constructor(private firestore: FirestoreService, private util: UtilService) {
        super('favorite');
    }

    public get(): Observable<FavoriteDto[]> {
        return this.firestore.getUserData<FavoriteDto>(this.baseCollection);
    }

    public getOne(id: string): Observable<FavoriteDto> {
        return this.firestore.getOne<FavoriteDto>(this.baseCollection, id);
    }

    public update(data: Partial<FavoriteDto>): Promise<void> {
        return this.firestore.update<FavoriteDto>(this.baseCollection, data.id, data);
    }

    public delete(id: string): Promise<any> {
        return this.firestore.delete(this.baseCollection, id);
    }
    
    public create(data: FavoriteDto): Promise<void> {
        return this.firestore.create(this.baseCollection, data);
    }

    public getFavoriteRoutine(uid: string, routineId: string): Observable<FavoriteDto[]> {
        return this.firestore.getFavoriteRoutine<FavoriteDto>(this.baseCollection, uid, routineId);
    }

    public getFavoritesByUserId(uid: string): Observable<FavoriteDto[]> {
        return this.firestore.getFavoritesByUserId<FavoriteDto>(this.baseCollection, uid);
    }

    public getFavoriteUserIdRoutineId(uid: string, routineId: string): Observable<FavoriteDto[]> {
        return this.firestore.getFavoriteUserIdRoutineId<FavoriteDto>(this.baseCollection, uid, routineId);
    }


}