import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

//Models
import { FavoriteDto, FavoriteModel } from 'src/app/core/models/favorite.model';

//Services
import { FavoriteDataService } from '../../../core/services/data-services/favorite-data.service';
import { UtilService } from '../../../core/services/util/util.service';
import { AuthenticationService } from '../../../core/services/firestore/firebase-authentication.service';

import { tap, map } from 'rxjs/operators';

@Component({
  selector: 'app-pozer-favorite',
  templateUrl: './pozer-favorite.page.html',
  styleUrls: ['./pozer-favorite.page.scss'],
})
export class PozerFavoritePage implements OnInit {
  public id: any;
  public routineId: any;
  public uid: any;
  public item: FavoriteDto;


  constructor(
    private favoriteDataService: FavoriteDataService,
    private navParams: NavParams,
    private modalController: ModalController,
    private authenticationService: AuthenticationService,
    private utilService: UtilService
  ) {
    this.id = this.navParams.get('id');
    this.routineId = this.navParams.get('routineId');
    this.uid = this.navParams.get('uid');
    this.item = FavoriteModel.emptyDto();

    this.authenticationService.authInfo$.pipe(tap(uid => {
      if (!uid) {
        this.utilService.navigate(`home`);
      } 
    })).subscribe();


    this.favoriteDataService.getOne(this.id).pipe(tap(data => {
      this.item = data;
      console.log(`${JSON.stringify(this.item, null, 2)}`);
    })).subscribe();
  }

  ngOnInit() {
  }

  toggle(day) {
    const data: any = {
      uid: this.uid,
      routineId: this.routineId
    }
    const trigger: any = {
      firstAt: 'next_' + day,
      every: 'week'
    }
    console.log(`${JSON.stringify(data, null, 2)}`);

    switch (day) {
      case 'sunday':
        if (this.item.week.sunday) {
          this.item.week.sunday = false;
        } else {
          this.item.week.sunday = true;
        }
        break;
      case 'monday':
        if (this.item.week.monday) {
          this.item.week.monday = false;
        } else {
          this.item.week.monday = true;
        }
        break;
      case 'tuesday':
        if (this.item.week.tuesday) {
          this.item.week.tuesday = false;
        } else {
          this.item.week.tuesday = true;
        }
        break;
      case 'wednesday':
        if (this.item.week.wednesday) {
          this.item.week.wednesday = false;
        } else {
          this.item.week.wednesday = true;
        }
        break;
      case 'thursday':
        if (this.item.week.thursday) {
          this.item.week.thursday = false;
        } else {
          this.item.week.thursday = true;
        }
        break;
      case 'friday':
        if (this.item.week.friday) {
          this.item.week.friday = false;
        } else {
          this.item.week.friday = true;
        }
        break;
      case 'saturday':
        if (this.item.week.saturday) {
          this.item.week.saturday = false;
        } else {
          this.item.week.saturday = true;
        }
        break;
    }
  }

  close() {
    this.favoriteDataService.update(this.item).then(() => {
      this.modalController.dismiss({
        'dismissed': true
      });
    });
  }

}
