import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
// import { SocialSharing } from '@ionic-native/social-sharing/ngx';

@Component({
  selector: 'app-pozer-share',
  templateUrl: './pozer-share.page.html',
  styleUrls: ['./pozer-share.page.scss'],
})
export class PozerSharePage implements OnInit {
  public logo: any;
  public link: any;
  public message: any;
  public subject: any;
  public fromEmail: any;
  public id: any;
  public routineId: any;

  constructor(
    private navParams: NavParams,
    private modalController: ModalController,
    // private socialSharing: SocialSharing
  ) { 
    this.id = this.navParams.get('id');
    // this.routineId = this.navParams.get('routineId');
    // this.message = this.navParams.get('message');
    // this.logo = this.navParams.get('image');

    this.logo = 'https://res.cloudinary.com/axiomaim/image/upload/v1581774701/pozer-img/logo_white.png';
    this.link = 'https://www.pozer.com/';
    this.subject = 'Pozer App';
    this.message = 'I’ll let you in on a secret, I’m squeezing in a workout with Pozer';
    this.fromEmail = 'info@pozer.com';
  }

  ngOnInit() {
  }

  close() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  chkEmail() {
    // Check if sharing via email is supported
    // this.socialSharing.canShareViaEmail().then(() => {
    //   // Sharing via email is possible
    //   alert(`Sharing via email is possible`);
    // }).catch(() => {
    //   // Sharing via email is not possible
    //   alert(`Sharing via email is not possible`);
    // });

  }

  shareEmail() {
    // Share via email
    // this.socialSharing.shareViaEmail(this.message, this.subject, [this.fromEmail]).then(() => {
    //   // Success!
    //   this.close();
    // }).catch(() => {
    //   // Error!
    //   this.close();
    // });
  }

  emailShare() {
    // // Share via email
    // this.socialSharing.shareViaEmail(this.message, this.subject, [this.fromEmail]).then(() => {
    //   // Success!
    //   this.close();
    // }).catch(() => {
    //   // Error!
    //   this.close();
    // });
    // this.socialSharing.shareViaEmail(
    //   this.message,
    //   'subject',
    //   [''],
    //   [''],
    //   [''],
    //   null
    // )
  }

  facebookShare() {
    // Share via email
    // this.socialSharing.shareViaFacebook(this.message, this.logo, this.link).then(() => {
    //   // Success!
    //   this.close();
    // }).catch(() => {
    //   // Error!
    //   this.close();
    // });

    // this.socialSharing.shareViaFacebook(
    //   this.message,
    //   this.logo,
    //   this.link
    //   )
  }

  instaGramShare() {
    // Share via email
    // this.socialSharing.shareViaInstagram(this.message, this.logo).then(() => {
    //   // Success!
    //   this.close();
    // }).catch(() => {
    //   // Error!
    //   this.close();
    // });
    // this.socialSharing.shareViaInstagram(
    //   this.message,
    //   this.image,
    // )
  }

  twitterShare() {
    // Share via email
    // this.socialSharing.shareViaTwitter(this.message, this.logo, this.link).then(() => {
    //   // Success!
    //   this.close();
    // }).catch(() => {
    //   // Error!
    //   this.close();
    // });
    
    // this.socialSharing.shareViaTwitter(
    //   this.message,
    //   this.logo,
    // )
  }

  smsShare() {
    // Share via email
    // this.socialSharing.shareViaSMS(this.message, this.subject).then(() => {
    //   // Success!
    //   this.close();
    // }).catch(() => {
    //   // Error!
    //   this.close();
    // });
    
    // this.socialSharing.shareViaSMS(
    //   this.message,
    //   this.logo,
    // )
  }

  whatsAppShare() {
    // Share via email
    // this.socialSharing.shareViaWhatsApp(this.message, this.logo, this.link).then(() => {
    //   // Success!
    //   this.close();
    // }).catch(() => {
    //   // Error!
    //   this.close();
    // });
    // this.socialSharing.shareViaWhatsApp(
    //   this.message,
    //   this.logo,
    // )
  }

  
}
