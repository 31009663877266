import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from './base-data.service';
import { FocusDto } from '../../models/focus.model';
import { FirestoreService } from '../firestore/firestore.service';
import { UtilService } from '../util/util.service';

@Injectable()
export class FocusDataService extends BaseDataService<FocusDto> {
    constructor(private firestore: FirestoreService, private util: UtilService) {
        super('focus');
    }

    public get(): Observable<FocusDto[]> {
        return this.firestore.get<FocusDto>(this.baseCollection);
    }

    public getOne(id: string): Observable<FocusDto> {
        return this.firestore.getOne<FocusDto>(this.baseCollection, id);
    }

    public update(data: Partial<FocusDto>): Promise<void> {
        return this.firestore.update<FocusDto>(this.baseCollection, data.id, data);
    }

    public delete(id: string): Promise<any> {
        return this.firestore.delete(this.baseCollection, id);
    }
    
    public create(data: FocusDto): Promise<void> {
        return this.firestore.create(this.baseCollection, data);
    }
}