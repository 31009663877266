import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from './base-data.service';
import { RoutineDto } from '../../models/routine.model';
import { FirestoreService } from '../firestore/firestore.service';
import { UtilService } from '../util/util.service';
import { map } from 'rxjs/operators';

@Injectable()
export class RoutineDataService extends BaseDataService<RoutineDto> {
    constructor(private firestore: FirestoreService, private util: UtilService) {
        super('routine');
    }

    public get(): Observable<RoutineDto[]> {
        return this.firestore.get<RoutineDto>(this.baseCollection);
        // return this.firestore.get<RoutineDto>(this.baseCollection).pipe(map(res => res));
    }

    public getPozerData(pozerId: string): Observable<RoutineDto[]> {
        return this.firestore.getPozerData<RoutineDto>(this.baseCollection, pozerId);
    }

    public getOne(id: string): Observable<RoutineDto> {
        return this.firestore.getOne<RoutineDto>(this.baseCollection, id);
    }

    public update(data: Partial<RoutineDto>): Promise<void> {
        return this.firestore.update<RoutineDto>(this.baseCollection, data.id, data);
    }

    public delete(id: string): Promise<any> {
        return this.firestore.delete(this.baseCollection, id);
    }
    
    public create(data: RoutineDto): Promise<void> {
        return this.firestore.create(this.baseCollection, data);
    }

    public getRoutinePosition(position: string): Observable<RoutineDto[]> {        
        return this.firestore.getRoutinePosition<RoutineDto>(this.baseCollection, position);
    }

    public getRoutinePositionLength(position: string, length: number): Observable<RoutineDto[]> {        
        return this.firestore.getRoutinePositionLength<RoutineDto>(this.baseCollection, position, length);
    }

}