import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule, HttpClient } from '@angular/common/http';
// import { IonicStorageModule } from '@ionic/storage';

// AngularFire
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireFunctionsModule } from '@angular/fire/functions';

// Environment
import { environment } from '../environments/environment';

// providers
import { AuthenticationService } from './core/services/firestore/firebase-authentication.service';
import { FirestoreService } from './core/services/firestore/firestore.service';
import { UtilService } from './core/services/util/util.service';
import { AppSettingsDataService } from './core/services/data-services/app-settings.service';
import { FocusDataService } from './core/services/data-services/focus-data.service';
import { PozerDataService } from './core/services/data-services/pozer-data.service';
import { RatingDataService } from './core/services/data-services/rating-data.service';
import { RoutineDataService } from './core/services/data-services/routine-data.service';
import { TargetAreaDataService } from './core/services/data-services/targetArea-data.service';
import { UserDataService } from './core/services/data-services/user-data.service';
import { UserFavoriteDataService } from './core/services/data-services/user-favorite-data.service';
import { UserRoutineDataService } from './core/services/data-services/user-routine-data.service';
import { UserLikeDataService } from './core/services/data-services/user-like-data.services';
import { PozeDataService } from './core/services/data-services/poze-data.service';
import { FavoriteDataService } from './core/services/data-services/favorite-data.service';
import { LogDataService } from './core/services/data-services/log-data.service';
import { DailLogDataService } from './core/services/data-services/daily-log.service';
import { MembershipDataService } from './core/services/data-services/membership-data.service';
import { MembershipTransactionsDataService } from './core/services/data-services/membership-transactions-data.service';
import { FeedbackDataService } from './core/services/data-services/feedback-data.service';
import { SupportDataService } from './core/services/data-services/support-data.service';
import { StorageService } from './core/services/firestore/filestorage.service';
// import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';

// import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
// import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
// import { Camera, CameraOptions } from '@ionic-native/camera/ngx';

// import { Device } from '@ionic-native/device/ngx';

import {DatePipe} from '@angular/common';

// MODAL
import { PozerAdminRoutineModalPageModule } from './pages/pozer-admin/pozer-admin-routine-modal/pozer-admin-routine-modal.module';
import { PozerRatingPageModule } from './pages/pozer/pozer-modals/pozer-rating/pozer-rating.module';
import { PozerSharePageModule } from './pages/pozer/pozer-modals/pozer-share/pozer-share.module';
import { PozerFeedbackPageModule } from './pages/pozer/pozer-modals/pozer-feedback/pozer-feedback.module';
import { PozerFavoritePageModule } from './pages/pozer/pozer-favorite/pozer-favorite.module';
import { PozerSupportPageModule } from './pages/pozer/pozer-modals/pozer-support/pozer-support.module';
import { PozerFaqPageModule } from './pages/pozer/pozer-modals/pozer-faq/pozer-faq.module';

// Components
import { PozerCountryComponent } from './components/pozer-country/pozer-country.component';
import { PozerLanguageComponent } from './components/pozer-language/pozer-language.component';
import { PozerPozeComponent } from './components/pozer-poze/pozer-poze.component';
import { PozerPozersComponent } from './components/pozer-pozers/pozer-pozers.component';

// import { SocialSharing } from '@ionic-native/social-sharing/ngx';

// import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';
// import { Diagnostic } from '@ionic-native/diagnostic/ngx';

// import { PozerRating1Component } from './components/pozer-rating1/pozer-rating1.component';
@NgModule({
  declarations: [
    AppComponent,
    PozerCountryComponent,
    PozerLanguageComponent,
    PozerPozeComponent,
    PozerPozersComponent,
    // PozerRating1Component
  ],
  entryComponents: [
    PozerCountryComponent,
    PozerLanguageComponent,
    PozerPozeComponent,
    PozerPozersComponent,
    // PozerRating1Component
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.config),
    // IonicStorageModule.forRoot(),
    FormsModule, 
    ReactiveFormsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    HttpClientModule,
    PozerAdminRoutineModalPageModule,
    PozerSharePageModule,
    PozerFeedbackPageModule,
    PozerSupportPageModule,
    PozerFavoritePageModule,
    PozerRatingPageModule,
    PozerFaqPageModule
    ],
  providers: [
    // StatusBar,
    // SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AppSettingsDataService,
    FocusDataService,
    PozerDataService,
    RatingDataService,
    RoutineDataService,
    TargetAreaDataService,
    UserDataService,
    // InAppBrowser,
    AuthenticationService,
    FirestoreService,
    UtilService,
    // FirebaseMessaging,
    PozeDataService,
    UserFavoriteDataService,
    UserRoutineDataService,
    UserLikeDataService,
    FavoriteDataService,
    LogDataService,
    // LocalNotifications,
    // Device,
    DailLogDataService,
    // SocialSharing,
    MembershipDataService,
    MembershipTransactionsDataService,
    // InAppPurchase2,
    DatePipe,
    FeedbackDataService,
    SupportDataService,
    StorageService,
    // Camera,
    // Diagnostic
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
