import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from './base-data.service';
import { RatingDto } from '../../models/rating.model';
import { FirestoreService } from '../firestore/firestore.service';
import { UtilService } from '../util/util.service';

@Injectable()
export class RatingDataService extends BaseDataService<RatingDto> {
    constructor(private firestore: FirestoreService, private util: UtilService) {
        super('rating');
    }

    public get(): Observable<RatingDto[]> {
        return this.firestore.get<RatingDto>(this.baseCollection);
    }

    public getRoutineId(routineId: string): Observable<RatingDto[]> {
        return this.firestore.getRoutineId<RatingDto>(this.baseCollection, routineId);
    }

    public getRatingRoutineUserId(uid: string, routineId: string): Observable<RatingDto[]> {
        return this.firestore.getRatingRoutineUserId<RatingDto>(this.baseCollection, uid, routineId);
    }

    public getOne(id: string): Observable<RatingDto> {
        return this.firestore.getOne<RatingDto>(this.baseCollection, id);
    }

    public update(data: Partial<RatingDto>): Promise<void> {
        return this.firestore.update<RatingDto>(this.baseCollection, data.id, data);
    }

    public delete(id: string): Promise<any> {
        return this.firestore.delete(this.baseCollection, id);
    }
    
    public create(data: RatingDto): Promise<void> {
        return this.firestore.create(this.baseCollection, data);
    }
}