import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from './base-data.service';
import { MembershipDto } from '../../models/membership.model';
import { FirestoreService } from '../firestore/firestore.service';
import { MembershipTransactionsDto } from 'src/app/core/models/membership-transactions.model';

@Injectable()
export class MembershipTransactionsDataService extends BaseDataService<MembershipTransactionsDto> {
    constructor(private firestore: FirestoreService) {
        super('membershipTransactions');
    }

    public get(): Observable<MembershipTransactionsDto[]> {
        return this.firestore.get<MembershipTransactionsDto>(this.baseCollection);
    }

    public getOne(id: string): Observable<MembershipTransactionsDto> {
        return this.firestore.getOne<MembershipTransactionsDto>(this.baseCollection, id);
    }

    public update(data: Partial<MembershipTransactionsDto>): Promise<void> {
        return this.firestore.update<MembershipTransactionsDto>(this.baseCollection, data.id, data);
    }

    public delete(id: string): Promise<any> {
        return this.firestore.delete(this.baseCollection, id);
    }
    
    public create(data: MembershipTransactionsDto): Promise<void> {
        return this.firestore.create(this.baseCollection, data);
    }
}