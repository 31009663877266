import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

import { Browser } from '@capacitor/browser';

@Component({
  selector: 'app-pozer-faq',
  templateUrl: './pozer-faq.page.html',
  styleUrls: ['./pozer-faq.page.scss'],
})
export class PozerFaqPage implements OnInit {

  constructor(
    private modalController: ModalController,
  ) { }

  ngOnInit() {
  }

  close() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  openTerms(){
    const openCapacitorSite = async () => {
      await Browser.open({ url: 'https://www.pozer.com/index.php?_route_=terms' });
    };

  }

}
