import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';

//Models
import { RatingDto, RatingModel } from 'src/app/core/models/rating.model';

//Services
import { RatingDataService } from '../../../../core/services/data-services/rating-data.service';

import { tap, map } from 'rxjs/operators';

@Component({
  selector: 'app-pozer-rating',
  templateUrl: './pozer-rating.page.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PozerRatingPage),
      multi: true
    }
  ],
  styleUrls: ['./pozer-rating.page.scss'],
})
export class PozerRatingPage implements OnInit {
  @Input()
  rate: number;
  @Input()
  readonly: boolean;
  @Input()
  size: string = 'default';
  @Input()
  rating_count: number = 0;
  @Output()
  rateChange: EventEmitter<number> = new EventEmitter();
  hoverRate: number;
  _onChange: Function;

  update: boolean = false;

  onClick(rate) {
    this.rate = rate;
    this.rateChange.emit(this.rate);
    this._onChange(this.rate);
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.rate = value;
    }
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }
  
  @Input() uid: string;
  @Input() routineId: string;
  public rating: RatingDto;

  constructor(
    private modalController: ModalController, 
    private navParams: NavParams,
    private ratingDataService: RatingDataService
  ) { 
    this.routineId = this.navParams.get('routineId');
    this.uid = this.navParams.get('uid');
    console.log(`routineId: ${this.routineId}`)
    console.log(`uid: ${this.uid}`)
    
    this.rating = RatingModel.emptyDto();
    this.rating.routineId = this.routineId;
    this.rating.uid = this.uid;

    console.log(`${JSON.stringify(this.rating, null, 2)}`)

    console.log(`${this.navParams.get('routineId')}`);
    this.ratingDataService.getRatingRoutineUserId(this.uid, this.routineId).pipe(tap(data => {
      console.log(`${JSON.stringify(data, null, 2)}`)
      if(data[0]) {
        this.update = true;
        this.rating = data[0];
        this.rate = this.rating.rating;
        console.log(`${JSON.stringify(this.rating, null, 2)}`)
      }
    })).subscribe();
  }

  ngOnInit() {
  }

  close() {

    this.rating.rating = this.rate;

    console.log(`${JSON.stringify(this.rating, null, 2)}`)
    if(this.update) {
      const datetime = new Date().toISOString();
      this.rating.updatedAt = datetime;
      this.ratingDataService.update(this.rating).then(() => {
        this.modalController.dismiss({
          'dismissed': true
        });  
      });
    } else {
      this.ratingDataService.create(this.rating).then(() => {
        this.modalController.dismiss({
          'dismissed': true
        });  
      });
    }
  }
}
