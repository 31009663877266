import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from './base-data.service';
import { SupportDto } from '../../models/support.model';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable()
export class SupportDataService extends BaseDataService<SupportDto> {
    constructor(private firestore: FirestoreService) {
        super('support');
    }

    public get(): Observable<SupportDto[]> {
        return this.firestore.get<SupportDto>(this.baseCollection);
    }

    public getOne(id: string): Observable<SupportDto> {
        return this.firestore.getOne<SupportDto>(this.baseCollection, id);
    }

    public update(data: Partial<SupportDto>): Promise<void> {
        return this.firestore.update<SupportDto>(this.baseCollection, data.id, data);
    }

    public delete(id: string): Promise<any> {
        return this.firestore.delete(this.baseCollection, id);
    }
    
    public create(data: SupportDto): Promise<void> {
        return this.firestore.create(this.baseCollection, data);
    }
}