import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-pozer-country',
  templateUrl: './pozer-country.component.html',
  styleUrls: ['./pozer-country.component.scss'],
})
export class PozerCountryComponent implements OnInit {
  constructor(
    private popoverController: PopoverController
  ) {

   }

  ngOnInit() {}

  selectItem(value: any) {
    const data = { value: value };
    this.popoverController.dismiss(data);
  }
}