import { UUID } from 'angular2-uuid';
import { BaseDatabaseModel, BaseDto } from './base-dto.model';
import { WeekDto, WeekModel } from './week.model';

export class FavoriteModel extends BaseDatabaseModel {

    constructor(
        id: string, 
        uid: string,
        routineId: string,
        week: WeekDto
        ) {
        super();
        this.id = id;
        this.uid = uid;
        this.routineId = routineId;
        this.week = week;
    }
    public id:string;
    public uid: string;
    public routineId: string;
    public week: WeekDto;

    public static fromDto(favorite: FavoriteDto): FavoriteModel {
        return new FavoriteModel(
            favorite.id, 
            favorite.uid, 
            favorite.routineId,
            favorite.week
            );
    }

    public static emptyDto(): FavoriteDto {
        return {
            id: UUID.UUID(),
            uid: null,
            routineId: null,
            week: WeekModel.emptyDto()
        }
    }

    public toDto(): FavoriteDto {
        return {
            id: this.id,
            uid: this.uid,
            routineId: this.routineId,
            week: this.week
        };
    }
}

export interface FavoriteDto extends BaseDto {
    id: string;
    uid: string;
    routineId: string;
    week: WeekDto;
}