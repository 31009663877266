import { UUID } from 'angular2-uuid';
import { BaseDatabaseModel, BaseDto } from './base-dto.model';

export class PozerModel extends BaseDatabaseModel {

    constructor(
        id: string, 
        name: string, 
        imageUrl?: string,
        createdAt?: string,
        updatedAt?: string,
        deletedAt?: string
        ) {
        super();
        this.id = id;
        this.name = name;
        this.imageUrl = imageUrl;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.deletedAt = deletedAt;
    }
    public id:string;
    public name: string;
    public imageUrl: string;
    public createdAt?: string;
    public updatedAt?: string;
    public deletedAt?: string;

    public static fromDto(routine: PozerDto): PozerModel {
        return new PozerModel(
            routine.id, 
            routine.name, 
            routine.imageUrl, 
            routine.createdAt,
            routine.updatedAt,
            routine.deletedAt
            );
    }

    public static emptyDto(): PozerDto {
        const datetime = new Date().toISOString();
        return {
            id: UUID.UUID(),
            name: null,
            imageUrl: null,
            createdAt: datetime,
            updatedAt: datetime,
            deletedAt: null
        }
    }

    public toDto(): PozerDto {
        return {
            id: this.id,
            name: this.name,
            imageUrl: this.imageUrl,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            deletedAt: this.deletedAt
        };
    }
}

export interface PozerDto extends BaseDto {
    id: string;
    name: string;
    imageUrl: string;
    createdAt?: string;
    updatedAt: string;
    deletedAt: string;
}