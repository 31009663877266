import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PozerFaqPage } from './pozer-faq.page';

const routes: Routes = [
  {
    path: '',
    component: PozerFaqPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PozerFaqPageRoutingModule {}
