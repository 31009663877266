import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PozerFeedbackPageRoutingModule } from './pozer-feedback-routing.module';

import { PozerFeedbackPage } from './pozer-feedback.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PozerFeedbackPageRoutingModule
  ],
  declarations: [PozerFeedbackPage]
})
export class PozerFeedbackPageModule {}
