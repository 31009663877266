import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/pozer/pozer-home/pozer-home.module').then( m => m.PozerHomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }


// import { NgModule } from '@angular/core';
// import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
// import { GuardsService } from './guards/guards.service';

// const routes: Routes = [
//   { path: '', redirectTo: 'home', pathMatch: 'full' },
//   // { path: 'home', loadChildren: './pages/pozer/pozer-home/pozer-home.module#PozerHomePageModule', canActivate: [GuardsService] },
//   { path: 'home', loadChildren: () => import('./pages/pozer/pozer-home/pozer-home.module').then(m => m.PozerHomePageModule), canActivate: [GuardsService], data: { breadcrumb: 'Home' } },
//   { path: 'pozer-home', loadChildren: './pages/pozer/pozer-home/pozer-home.module#PozerHomePageModule' },
//   { path: 'signup', loadChildren: './pages/firebase/signup/signup.module#SignupPageModule'},
//   { path: 'login', loadChildren: './pages/firebase/login/login.module#LoginPageModule' },
//   { path: 'pozer-help', loadChildren: './pages/pozer/pozer-help/pozer-help.module#PozerHelpPageModule' },
//   { path: 'pozer-position/:length/:type', loadChildren: './pages/pozer/pozer-position/pozer-position.module#PozerPositionPageModule' },
//   { path: 'pozer-profile', loadChildren: './pages/pozer/pozer-profile/pozer-profile.module#PozerProfilePageModule' },
//   { path: 'pozer-settings', loadChildren: './pages/pozer/pozer-settings/pozer-settings.module#PozerSettingsPageModule' },
//   { path: 'pozer-setup-walkthrough', loadChildren: './pages/pozer/pozer-setup-walkthrough/pozer-setup-walkthrough.module#PozerSetupWalkthroughPageModule' },
//   { path: 'pozer-tour', loadChildren: './pages/pozer/pozer-tour/pozer-tour.module#PozerTourPageModule' },
//   { path: 'pozer-verify', loadChildren: './pages/pozer/pozer-verify/pozer-verify.module#PozerVerifyPageModule' },
//   { path: 'pozer-routine/:id', loadChildren: './pages/pozer/pozer-routine/pozer-routine.module#PozerRoutinePageModule' },
//   { path: 'pozer-welcome', loadChildren: './pages/pozer/pozer-welcome/pozer-welcome.module#PozerWelcomePageModule' },
//   { path: 'pozer-rating', loadChildren: './pages/pozer/pozer-modals//pozer-rating/pozer-rating.module#PozerRatingPageModule' },
//   { path: 'pozer-log', loadChildren: './pages/pozer/pozer-log/pozer-log.module#PozerLogPageModule' },
//   { path: 'pozer-daily', loadChildren: './pages/pozer/pozer-daily/pozer-daily.module#PozerDailyPageModule' },

//   { path: 'pozer-admin-routine-list', loadChildren: './pages/pozer-admin/pozer-admin-routine-list/pozer-admin-routine-list.module#PozerAdminRoutineListPageModule' },
//   { path: 'pozer-admin-routine-modal/:id', loadChildren: './pages/pozer-admin/pozer-admin-routine-modal/pozer-admin-routine-modal.module#PozerAdminRoutineModalPageModule' },
//   { path: 'pozer-admin-pozer-list', loadChildren: './pages/pozer-admin/pozer-admin-pozer-list/pozer-admin-pozer-list.module#PozerAdminPozerListPageModule' },
//   { path: 'pozer-admin-pozer-modal/:id', loadChildren: './pages/pozer-admin/pozer-admin-pozer-modal/pozer-admin-pozer-modal.module#PozerAdminPozerModalPageModule' },
//   { path: 'pozer-admin-poze-list', loadChildren: './pages/pozer-admin/pozer-admin-poze-list/pozer-admin-poze-list.module#PozerAdminPozeListPageModule' },
//   { path: 'pozer-admin-poze-modal/:id', loadChildren: './pages/pozer-admin/pozer-admin-poze-modal/pozer-admin-poze-modal.module#PozerAdminPozeModalPageModule' },
//   { path: 'pozer-membership', loadChildren: './pages/pozer/pozer-membership/pozer-membership.module#PozerMembershipPageModule' },
//   { path: 'pozer-admin-user-list', loadChildren: './pages/pozer-admin/pozer-admin-user-list/pozer-admin-user-list.module#PozerAdminUserListPageModule' },
//   { path: 'pozer-admin-user-modal/:id', loadChildren: './pages/pozer-admin/pozer-admin-user-modal/pozer-admin-user-modal.module#PozerAdminUserModalPageModule' },
//   { path: 'pozer-admin-notifications/:id', loadChildren: './pages/pozer-admin/pozer-admin-notifications/pozer-admin-notifications.module#PozerAdminNotificationsPageModule' },
//   { path: 'pozer-admin-notifications-list', loadChildren: './pages/pozer-admin/pozer-admin-notifications-list/pozer-admin-notifications-list.module#PozerAdminNotificationsListPageModule' },
//   { path: 'pozer-log-modal/:date', loadChildren: './pages/pozer/pozer-log-modal/pozer-log-modal.module#PozerLogModalPageModule' },
//   { path: 'pozer-admin-muscle-grp', loadChildren: './pages/pozer-admin/pozer-admin-muscle-grp/pozer-admin-muscle-grp.module#PozerAdminMuscleGrpPageModule' },
//   { path: 'pozer-daily-modal/:id', loadChildren: './pages/pozer/pozer-daily-modal/pozer-daily-modal.module#PozerDailyModalPageModule' },
//   { path: 'pozer-admin-angular-video/:id', loadChildren: './pages/pozer-admin/pozer-admin-angular-video/pozer-admin-angular-video.module#PozerAdminAngularVideoPageModule' },
//   {
//     path: 'pozer-admin-membership-modal/:id',
//     loadChildren: () => import('./pages/pozer-admin/pozer-admin-membership-modal/pozer-admin-membership-modal.module').then( m => m.PozerAdminMembershipModalPageModule)
//   },
//   {
//     path: 'pozer-admin-membership-list',
//     loadChildren: () => import('./pages/pozer-admin/pozer-admin-membership-list/pozer-admin-membership-list.module').then( m => m.PozerAdminMembershipListPageModule)
//   },
//   {
//     path: 'pozer-admin-membership-catalog',
//     loadChildren: () => import('./pages/pozer-admin/pozer-admin-membership-catalog/pozer-admin-membership-catalog.module').then( m => m.PozerAdminMembershipCatalogPageModule)
//   },
//   {
//     path: 'pozer-feedback',
//     loadChildren: () => import('./pages/pozer/pozer-modals/pozer-feedback/pozer-feedback.module').then( m => m.PozerFeedbackPageModule)
//   },
//   {
//     path: 'pozer-support',
//     loadChildren: () => import('./pages/pozer/pozer-modals/pozer-support/pozer-support.module').then( m => m.PozerSupportPageModule)
//   },
//   {
//     path: 'pozer-faq',
//     loadChildren: () => import('./pages/pozer/pozer-modals/pozer-faq/pozer-faq.module').then( m => m.PozerFaqPageModule)
//   },
//   {
//     path: 'pozer-home-public',
//     loadChildren: () => import('./pages/pozer/pozer-home-public/pozer-home-public.module').then( m => m.PozerHomePublicPageModule)
//   },
//   {
//     path: 'register',
//     loadChildren: () => import('./pages/firebase/register/register.module').then( m => m.RegisterPageModule)
//   },
//   {
//     path: 'pozer-body',
//     loadChildren: () => import('./pages/pozer/pozer-body/pozer-body.module').then( m => m.PozerBodyPageModule)
//   },
//   // { path: '**', redirectTo: '/login' }
// ];

// @NgModule({
//   imports: [
//     RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
//   ],
//   exports: [RouterModule]
// })
// export class AppRoutingModule { }
