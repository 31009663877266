import { UUID } from 'angular2-uuid';
import { BaseDatabaseModel, BaseDto } from './base-dto.model';
import { PozerDto, PozerModel } from './pozer.model';
import { PozeDto, PozeModel } from './poze.model';

export class RoutineModel extends BaseDatabaseModel {

    constructor(
        id: string, 
        pozerId?: string,
        pozer?: PozerDto,
        pozeId?: string,
        poze?: PozeDto,
        title?: number,
        moves?: number,
        views?: number,
        length?: number,
        likes?: number,
        dislikes?: number,
        favorites?: number,
        imageUrl?: string,
        videoUrl?: string,
        videoId?: string,
        rating?: number,
        createdAt?: string,
        updatedAt?: string,
        deletedAt?: string
        ) {
        super();
        this.id = id;
        this.pozerId = pozerId;
        this.pozer = pozer;
        this.pozeId = pozeId;
        this.poze = poze;
        this.title = title;
        this.moves = moves;
        this.views = views;
        this.length = length;
        this.likes = likes;
        this.dislikes = dislikes;
        this.favorites = favorites;
        this.imageUrl = imageUrl;
        this.videoUrl = videoUrl;
        this.videoId = videoId;
        this.rating = rating;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.deletedAt = deletedAt;
    }
    public id:string;
    public pozerId?: string;
    public pozer?: PozerDto;
    public pozeId?: string;
    public poze?: PozeDto;
    public title?: number;
    public moves?: number;
    public views?: number;
    public length?: number;
    public likes?: number;
    public dislikes?: number;
    public favorites?: number;
    public imageUrl?: string;
    public videoUrl?: string;
    public videoId?: string;
    public rating?: number;
    public createdAt?: string;
    public updatedAt?: string;
    public deletedAt?: string;

    public static fromDto(routine: RoutineDto): RoutineModel {
        return new RoutineModel(
            routine.id, 
            routine.pozerId,
            routine.pozer,
            routine.pozeId,
            routine.poze,
            routine.title,
            routine.moves,
            routine.views,
            routine.length,
            routine.likes,
            routine.dislikes,
            routine.favorites,
            routine.imageUrl,
            routine.videoUrl,
            routine.videoId,
            routine.rating,
            routine.createdAt,
            routine.updatedAt,
            routine.deletedAt
            );
    }

    public static emptyDto(): RoutineDto {
        const datetime = new Date().toISOString();
        return {
            id: UUID.UUID(),
            pozerId: null,
            pozer: PozerModel.emptyDto(),
            pozeId: null,
            poze: PozeModel.emptyDto(),
            title: null,
            views: null,
            moves: 0,
            length: 0,
            likes: 0,
            dislikes: 0,
            favorites: 0,
            imageUrl: null,
            videoUrl: null,
            videoId: null,
            rating: 0,
            createdAt: datetime,
            updatedAt: datetime,
            deletedAt: null
        }
    }

    public toDto(): RoutineDto {
        return {
            id: this.id,
            pozerId: this.pozerId,
            pozer: this.pozer,
            pozeId: this.pozeId,
            poze: this.poze,
            title: this.title,
            moves: this.moves,
            views: this.views,
            length: this.length,
            likes: this.likes,
            dislikes: this.dislikes,
            favorites: this.favorites,
            imageUrl: this.imageUrl,
            videoUrl: this.videoUrl,
            videoId: this.videoId,
            rating: this.rating,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            deletedAt: this.deletedAt
        };
    }
}

export interface RoutineDto extends BaseDto {
    id: string;
    pozerId?: string;
    pozer?: PozerDto;
    pozeId?: string;
    poze?: PozeDto;
    title?: number;
    views?: number;
    moves?: number;
    length?: number;
    likes?: number;
    dislikes?: number;
    favorites?: number;
    imageUrl?: string;
    videoUrl?: string;
    videoId?: string;
    rating?: number;
    createdAt?: string;
    updatedAt: string;
    deletedAt: string;
}