import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PozerFaqPageRoutingModule } from './pozer-faq-routing.module';

import { PozerFaqPage } from './pozer-faq.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PozerFaqPageRoutingModule
  ],
  declarations: [PozerFaqPage]
})
export class PozerFaqPageModule {}
