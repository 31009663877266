import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { AuthenticationService } from '../../../core/services/firestore/firebase-authentication.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { UtilService } from '../../../core/services/util/util.service';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

import { RoutineDataService } from '../../../core/services/data-services/routine-data.service';
import { RoutineDto, RoutineModel } from 'src/app/core/models/routine.model';

import { PozerDataService } from '../../../core/services/data-services/pozer-data.service';
import { PozerDto, PozerModel } from 'src/app/core/models/pozer.model';

import { PozeDataService } from '../../../core/services/data-services/poze-data.service';
import { PozeDto, PozeModel } from 'src/app/core/models/poze.model';

import { PozerPozersComponent } from '../../../components/pozer-pozers/pozer-pozers.component';
import { PozerPozeComponent } from '../../../components/pozer-poze/pozer-poze.component';

import { tap, map } from 'rxjs/operators';

@Component({
  selector: 'app-pozer-admin-routine-modal',
  templateUrl: './pozer-admin-routine-modal.page.html',
  styleUrls: ['./pozer-admin-routine-modal.page.scss'],
})
export class PozerAdminRoutineModalPage implements OnInit {
  public routine: RoutineDto;
  public pozerList: PozerDto[];
  public pozeList: PozeDto[];
  public pozer: PozerDto;
  public poze: PozeDto;
  public id: any;
  public trustedVideoUrl: SafeResourceUrl;

    constructor(
      private authenticationService: AuthenticationService,
      private routineDataService: RoutineDataService,
      private pozerDataService: PozerDataService,
      private pozeDataService: PozeDataService,
      private route: ActivatedRoute,
      private utilService: UtilService,
      private modalController: ModalController,
      private popoverController: PopoverController,
      private sanitizer: DomSanitizer
    ) {
      this.id = this.route.snapshot.paramMap.get('id');
      console.log(`id: ${this.id}`);
      this.routine = RoutineModel.emptyDto();
      console.log(`routine: ${JSON.stringify(this.routine)}`);
      this.pozer = PozerModel.emptyDto();
      console.log(`pozer: ${JSON.stringify(this.pozer)}`);
      this.poze = PozeModel.emptyDto();
      console.log(`poze: ${JSON.stringify(this.poze)}`);
     }
  
    ngOnInit() {
      if (this.id !== 'null') {
        this.authenticationService.checkAuth().then(user => {
          console.log(`wtf id=${this.id}`);
          this.routineDataService.getOne(this.id).pipe(tap(data => {
            this.routine = data;
            this.trustedVideoUrl = this.sanitizer.bypassSecurityTrustHtml(this.routine.videoUrl);
            this.pozerDataService.getOne(this.routine.pozerId).pipe(tap(pozer => {
              this.pozer = pozer;
              this.routine.pozer = pozer;              
            })).subscribe();
            this.pozeDataService.getOne(this.routine.pozeId).pipe(tap(poze => {
              this.poze = poze;
              this.routine.poze = poze;
            })).subscribe();
          })).subscribe();
        });
      } 

      // this.pozerDataService.get().subscribe(pozerList => {
      //   this.pozerList = pozerList;
      // })

      // this.pozeDataService.get().subscribe(pozeList => {
      //   this.pozeList = pozeList;
      // })

    }
    
    save(nav: boolean){
      this.routineDataService.create(this.routine).then(data => {
        console.log(`SAVED`);
        if(nav){
          this.utilService.navigate('/pozer-admin-routine-list', false);
        }
      });
    }

    close() {
      this.modalController.dismiss();
    }
  
  
    async popoverPozer(ev: any) {
      const popover = await this.popoverController.create({
        component: PozerPozersComponent,
        event: ev,
        translucent: true
      });
  
      popover.onDidDismiss().then(data => {
        const thisData: any = data;
        this.routine.pozerId = thisData.data.value;
        this.pozerDataService.getOne(thisData.data.value).pipe(tap(data => {
          this.pozer = data;
          this.routine.pozer = data;
        })).subscribe();
      })
  
      return await popover.present();
    }
  
    async popoverPoze(ev: any) {
      const popover = await this.popoverController.create({
        component: PozerPozeComponent,
        event: ev,
        translucent: true
      });
  
      popover.style.cssText = '--height: 350px; --max-height: 350px;';
    
      popover.onDidDismiss().then(data => {
        const thisData: any = data;
        this.routine.pozeId = thisData.data.value;
        this.pozeDataService.getOne(thisData.data.value).pipe(tap(data => {
          this.poze = data;
          this.routine.poze = data;
        })).subscribe();
      })
  
      return await popover.present();
    }
  
  }
  