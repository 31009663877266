import { UUID } from 'angular2-uuid';
import { BaseDatabaseModel, BaseDto } from './base-dto.model';

export class RatingModel extends BaseDatabaseModel {

    constructor(
        id: string, 
        uid: string, 
        routineId: string, 
        rating: number, 
        createdAt?: string,
        updatedAt?: string,
        deletedAt?: string
        ) {
        super();
        this.id = id;
        this.uid = uid;
        this.routineId = routineId;
        this.rating = rating;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.deletedAt = deletedAt;
    }
    public id:string;
    public uid: string;
    public routineId: string;
    public rating: number;
    public createdAt?: string;
    public updatedAt?: string;
    public deletedAt?: string;

    public static fromDto(routine: RatingDto): RatingModel {
        return new RatingModel(
            routine.id, 
            routine.uid, 
            routine.routineId, 
            routine.rating, 
            routine.createdAt,
            routine.updatedAt,
            routine.deletedAt
            );
    }

    public static emptyDto(): RatingDto {
        const datetime = new Date().toISOString();
        return {
            id: UUID.UUID(),
            uid: null,
            routineId: null,
            rating: 0,
            createdAt: datetime,
            updatedAt: datetime,
            deletedAt: null
        }
    }

    public toDto(): RatingDto {
        return {
            id: this.id,
            uid: this.uid,
            routineId: this.routineId,
            rating: this.rating,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            deletedAt: this.deletedAt
        };
    }
}

export interface RatingDto extends BaseDto {
    id: string;
    uid: string;
    routineId: string;
    rating: number;
    createdAt?: string;
    updatedAt: string;
    deletedAt: string;
}