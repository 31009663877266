import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-pozer-language',
  templateUrl: './pozer-language.component.html',
  styleUrls: ['./pozer-language.component.scss'],
})
export class PozerLanguageComponent implements OnInit {

  constructor(
    private popoverController: PopoverController
  ) {

   }

  ngOnInit() {}

  selectItem(value: any) {
    const data = { value: value };
    this.popoverController.dismiss(data);
  }

}
