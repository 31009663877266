import { UUID } from 'angular2-uuid';
import { BaseDatabaseModel, BaseDto } from './base-dto.model';

export class FocusModel extends BaseDatabaseModel {

    constructor(
        id: string, 
        flexibility: boolean,
        strength: boolean,
        fun: boolean,
        weightloss: boolean,
        savetime: boolean,
        savemoney: boolean,
        ) {
        super();
        this.id = id;
        this.flexibility = flexibility;
        this.strength = strength;
        this.fun = fun;
        this.weightloss = weightloss;
        this.savetime = savetime;
        this.savemoney = savemoney;
    }
    public id:string;
    public flexibility: boolean;
    public strength: boolean;
    public fun: boolean;
    public weightloss: boolean;
    public savetime: boolean;
    public savemoney: boolean;
    public createdAt?: string;
    public updatedAt?: string;
    public deletedAt?: string;

    public static fromDto(focus: FocusDto): FocusModel {
        return new FocusModel(
            focus.id, 
            focus.flexibility, 
            focus.strength, 
            focus.fun, 
            focus.weightloss, 
            focus.savetime, 
            focus.savemoney, 
            );
    }

    public static emptyDto(): FocusDto {
        const datetime = new Date().toISOString();
        return {
            id: UUID.UUID(),
            flexibility: false,
            strength: false,
            fun: false,
            weightloss: false,
            savetime: false,
            savemoney: false,
        }
    }

    public toDto(): FocusDto {
        return {
            id: this.id,
            flexibility: this.flexibility,
            strength: this.strength,
            fun: this.fun,
            weightloss: this.weightloss,
            savetime: this.savetime,
            savemoney: this.savemoney,
        };
    }
}

export interface FocusDto extends BaseDto {
    id: string;
    flexibility: boolean;
    fun: boolean;
    strength: boolean;
    weightloss: boolean;
    savetime: boolean;
    savemoney: boolean;
}