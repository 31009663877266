import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from './base-data.service';
import { RatingDto } from '../../models/rating.model';
import { FirestoreService } from '../firestore/firestore.service';
import { UtilService } from '../util/util.service';
import { PozeDto } from 'src/app/core/models/poze.model';
import { DailyLogDto } from 'src/app/core/models/daily-log.model';

@Injectable()
export class DailLogDataService extends BaseDataService<DailyLogDto> {
    constructor(private firestore: FirestoreService, private util: UtilService) {
        super('daily');
    }

    public get(): Observable<DailyLogDto[]> {
        return this.firestore.getUserData<DailyLogDto>(this.baseCollection);
    }

    public getDailyLog(dateInt: any): Observable<DailyLogDto[]> {
        return this.firestore.getDailyLog<DailyLogDto>(this.baseCollection, dateInt);
    }

    public getTodaysLog(date: any): Observable<DailyLogDto[]> {
        return this.firestore.getDailyLog<DailyLogDto>(this.baseCollection, date);
    }

    public getOne(id: string): Observable<DailyLogDto> {
        return this.firestore.getOne<DailyLogDto>(this.baseCollection, id);
    }

    public update(data: Partial<DailyLogDto>): Promise<void> {
        return this.firestore.update<DailyLogDto>(this.baseCollection, data.id, data);
    }

    public delete(id: string): Promise<any> {
        return this.firestore.delete(this.baseCollection, id);
    }
    
    public create(data: DailyLogDto): Promise<void> {
        return this.firestore.create(this.baseCollection, data);
    }

    public rollupDailyLog() {

    }
}